import { useState,useEffect } from "react";
import { initializeApp } from "firebase/app";
import {getFirestore,onSnapshot, doc,} from "firebase/firestore"
import { useNavigate } from 'react-router-dom';
import { FBDataBase,FBApp } from "../../Firebase/firebase";
import { useUserData } from "../../Contexts/UserDataContext";

export default function JobTilesPage() {
const {UID,userData,userCompanyData} = useUserData()
const navigate = useNavigate();

const [planSetList,setPlanSetList] = useState([])
const [hoveredIndex, setHoveredIndex] = useState(null);

const [projectConstants,setProjectConstants] = useState({
  co: "AMP",
  prefix:"gs://plan-room.appspot.com",
  defaultScale: 16
})


  ////// MASTER DOCUMENT //////
useEffect(() => {
  console.log(UID,userData,userCompanyData)
  const docRef = doc(FBDataBase, projectConstants.co, "INDEX");

  const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      let docData = docSnapshot.data()
      console.log("Document data:", docData.planSets);
      setPlanSetList([...docData.planSets])
      console.log([...docData.planSets])
      console.log(projectConstants)
    } else {
      console.log("No such document!");
    }
  });

  return () => unsubscribe();
}, [FBDataBase]);


////// METHODS //////
const getHoverStyles = (index, color) => {
  if (index === hoveredIndex) {
    return {
      filter: `drop-shadow(0px 0px 20px ${color})`,
      border: `2px solid ${color}`,
      backgroundColor: "#282828",
    };
  }
  return {};
};

const tileClickHandler = (title) => {
  navigate('/plan-room/app', { state: { myData: title } })
}
////// STYLES //////
const styles = {
  app:{
    width:"100%",
    // height:"100%"
  },
  leftNav:{
    position:"absolute",
    zIndex:"10",
    width:"30em",
    // height:"100%",
    backgroundColor:"#282828",
    border:"2px solid white",
    margin:"0.5em",
    borderRadius:"0.75em",
    color:"white",
  },
  leftNavButton:{
    userSelect:"none",
    cursor:"pointer",
    boxSizing:"border-box",
    border:"2px solid white",
    width:"29.5em",
    height:"3em",
    margin:"0.25em",
    borderRadius:"0.5em",
    // fontWeight:"bold",
    transition: 'color 0.1s, filter 0.1s',
  },
  leftNavButtonBox:{
    display:"flex",
    alignItems:"center",
    lineHeight:"1.8em",
    justifyContent:"flex-start",
    flexDirection:"row",
    fontSize:"1.5em"
  },
  mainPane:{
    width:"100vw",
    // height:"100%",
    position:"absolute",
    zIndex:"1",
    backgroundColor:"gray"
  },
  infoBorder:{},
  infoHeader:{},
  infoList:{},
  
}

return(
<div style={styles.app}>
      <div style={styles.leftNav}>
        {planSetList.map((d, i) => {
          return (
            <div
              key={i}
              onClick={()=>{tileClickHandler(d.title)}}
              className="leftNavButton"
              style={{
                ...styles.leftNavButton,
                ...getHoverStyles(i, d.color),
              }}
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div style={styles.leftNavButtonBox}>• {d.title}</div>
            </div>
          );
        })}
      </div>
      <div style={styles.mainPane}></div>
    </div>
)

}
